import { useAuth } from "@/contexts/auth";
import { OEE } from "@/widgets/oee";
import { type FC } from "react";
import { useIntl } from "react-intl";

export const Dashboard: FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuth();
  return (
    <div>
      <div className="mx-auto max-w-7xl px-2 py-24 sm:py-12 lg:px-8">
        <h2 className="max-w-2xl text-balance text-4xl font-semibold tracking-tight sm:text-5xl">
          {formatMessage({ id: "welcome_x" }, { name: user?.firstName })}
        </h2>
      </div>
      <div className="mt-5 grid gap-4 sm:mt-8 lg:grid-cols-3 lg:grid-rows-2">
        <OEE />
        <OEE />
        <OEE />
      </div>
    </div>
  );
};

export default Dashboard;
