import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { getLast7Days } from "@/lib/utils";
import { Info } from "lucide-react";
import { useIntl } from "react-intl";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";

export function OEE() {
  const { formatMessage, formatDate } = useIntl();
  const last7days = getLast7Days(formatDate, true);
  const percentData = [0, 0, 0, 0, 0, 0, 0];
  const chartData = last7days.days.map((day, index) => ({
    day: day.shortDay,
    percent: percentData[index] || 0,
  }));
  const chartConfig = {
    desktop: {
      label: "OEE",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig;
  return (
    <Card>
      <CardHeader>
        <CardTitle>{formatMessage({ id: "weekly_oee_overview" })}</CardTitle>
        <CardDescription>
          {last7days.startDate} - {last7days.endDate}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="day"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar
              dataKey="percent"
              label="OEE"
              fill="var(--color-desktop)"
              radius={8}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        {/* <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div> */}
        <div className="flex gap-2 font-medium leading-none text-muted-foreground hover:text-foreground">
          <Info className="h-4 w-4" />
          <div className="leading-none">
            {formatMessage({
              id: "overall_equipment_effectiveness_for_the_past_7_days",
            })}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
