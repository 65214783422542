import { AppSidebar } from "@/components/custom/nav/app-sidebar";
import { Outlet } from "react-router-dom";

import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import { useAuth } from "@/contexts/auth";
import { createAclify } from "react-aclify";

type Role = "user";
type Permission =
  | "factory:production-orders:create"
  | "factory:production-orders:update"
  | "factory:production-orders:delete"
  | "factory:products:read"
  | "factory:products:create"
  | "factory:products:update"
  | "factory:products:delete"
  | "factory:raw-materials:read"
  | "factory:raw-materials:create"
  | "factory:raw-materials:update"
  | "factory:raw-materials:delete"
  | "factory:planning:read"
  | "factory:planning:create"
  | "factory:planning:update"
  | "factory:settings:read"
  | "factory:settings:update"
  | "factory:overview"
  | "finance:overview"
  | "finance:inventories:read"
  | "finance:inventories:create"
  | "finance:inventories:update"
  | "finance:inventories:delete"
  | "finance:employees:read"
  | "finance:employees:create"
  | "finance:employees:update"
  | "finance:employees:delete"
  | "quality:overview"
  | "quality:items:read"
  | "quality:items:create"
  | "quality:items:update"
  | "quality:items:delete"
  | "quality:work-flows:read"
  | "quality:work-flows:create"
  | "quality:work-flows:update"
  | "quality:work-flows:delete"
  | "factory:production-orders:read"
  | "settings:overview"
  | "supply:overview"
  | "supply:suppliers:read"
  | "supply:suppliers:create"
  | "supply:suppliers:update"
  | "supply:suppliers:delete"
  | "supply:purchase-orders:read"
  | "supply:purchase-orders:create"
  | "supply:purchase-orders:update"
  | "supply:purchase-orders:delete"
  | "supply:goods-delivery-notes:read"
  | "supply:goods-delivery-notes:create"
  | "supply:goods-delivery-notes:update"
  | "supply:goods-delivery-notes:delete"
  | "supply:items:read"
  | "supply:items:create"
  | "supply:items:update"
  | "supply:items:delete"
  | "sales:overview"
  | "sales:customers:read"
  | "sales:customers:create"
  | "sales:customers:update"
  | "sales:customers:delete"
  | "sales:orders:read"
  | "sales:orders:create"
  | "sales:orders:update"
  | "sales:orders:delete"
  | "sales:products:read"
  | "sales:products:create"
  | "sales:products:update"
  | "sales:products:delete"
  | "sales:contacts:read"
  | "sales:contacts:create"
  | "sales:contacts:update"
  | "sales:contacts:delete"
  | "sales:leads:read"
  | "sales:leads:create"
  | "sales:leads:update"
  | "sales:leads:delete"
  | "sales:deals:read"
  | "sales:deals:create"
  | "sales:deals:update"
  | "sales:deals:delete"
  | "pod:quality-inspection:read"
  | "pod:quality-inspection:create"
  | "pod:quality-inspection:update"
  | "pod:quality-inspection:delete"
  | "pod:production-records:read"
  | "pod:production-records:create"
  | "pod:production-records:update"
  | "pod:production-records:delete"
  | "pod:note:read"
  | "pod:note:create"
  | "pod:note:update"
  | "pod:note:delete";
export const { CanAccess, useAclify, AclifyProvider } = createAclify<
  Role,
  Permission
>();

export const AuthLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export const MainLayout = () => {
  const { permissions } = useAuth();
  return (
    <>
      <AclifyProvider userRoles={["user"]} userPermissions={permissions}>
        <SidebarProvider>
          <AppSidebar />
          <SidebarInset>
            <div className="p-2">
              <Outlet />
            </div>
          </SidebarInset>
        </SidebarProvider>
      </AclifyProvider>
    </>
  );
};

export default MainLayout;
