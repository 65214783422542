import { type LucideIcon, Send } from "lucide-react";
import * as React from "react";

import { FeedbackDialog } from "@/components/custom/nav/feedback";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useIntl } from "react-intl";

export function NavSecondary({
  items,
  ...props
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
  }[];
} & React.ComponentPropsWithoutRef<typeof SidebarGroup>) {
  const { formatMessage } = useIntl();
  return (
    <SidebarGroup {...props}>
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton asChild size="sm">
                <a href={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
          <FeedbackDialog>
            <SidebarMenuItem key="feedback">
              <SidebarMenuButton size="sm">
                <Send />
                {formatMessage({ id: "nav.secondary.feedback" })}
              </SidebarMenuButton>
            </SidebarMenuItem>
          </FeedbackDialog>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
