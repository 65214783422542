import {
  BookOpen,
  Bot,
  Frame,
  Home,
  LifeBuoy,
  Settings2,
  SquareTerminal,
} from "lucide-react";
import type * as React from "react";

import { NavMain } from "@/components/custom/nav/nav-main";
import { NavPersonal } from "@/components/custom/nav/nav-personal";
import { NavSecondary } from "@/components/custom/nav/nav-secondary";
import { NavUser } from "@/components/custom/nav/nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useAuth } from "@/contexts/auth";
import { useIntl } from "react-intl";
import { NavTop } from "./nav-top";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { formatMessage } = useIntl();
  const { user } = useAuth();
  const data = {
    navTop: [
      {
        title: formatMessage({ id: "nav.top.home" }),
        url: "/",
        icon: Home,
        isActive: true,
      },
      // {
      //   title: formatMessage({ id: "nav.top.inbox" }),
      //   url: "/inbox",
      //   icon: Inbox,
      //   badge: "10",
      // },
    ],
    navMain: [
      {
        title: formatMessage({ id: "nav.factory.production_schedule" }),
        url: "#",
        icon: SquareTerminal,
        items: [
          {
            title: formatMessage({
              id: "nav.factory.production_schedule.production_orders",
            }),
            url: "/factory/production-orders",
          },
          {
            title: formatMessage({
              id: "nav.factory.production_schedule.work_orders",
            }),
            url: "/factory/work-orders",
          },
        ],
      },
      {
        title: formatMessage({ id: "nav.factory.resources" }),
        url: "#",
        icon: Bot,
        items: [
          {
            title: formatMessage({ id: "nav.factory.resources.planning" }),
            url: "/factory/planning",
          },
        ],
      },
      {
        title: formatMessage({ id: "nav.factory.materials" }),
        url: "#",
        icon: BookOpen,
        items: [
          {
            title: formatMessage({
              id: "nav.factory.materials.raw_materials",
            }),
            url: "/factory/raw-materials",
          },
          {
            title: formatMessage({
              id: "nav.factory.materials.products",
            }),
            url: "/factory/products",
          },
          {
            title: formatMessage({
              id: "nav.factory.materials.others",
            }),
            url: "#",
          },
        ],
      },
      {
        title: formatMessage({ id: "nav.factory.settings" }),
        url: "#",
        icon: Settings2,
        items: [
          {
            title: formatMessage({
              id: "nav.factory.settings.production_settings",
            }),
            url: "/factory/settings",
          },
        ],
      },
    ],
    navSecondary: [
      {
        title: formatMessage({ id: "nav.secondary.support" }),
        url: "#",
        icon: LifeBuoy,
      },
    ],
    personal: [
      {
        name: formatMessage({ id: "nav.personal.my_tasks" }),
        url: "/my-tasks",
        icon: Frame,
      },
      {
        name: formatMessage({
          id: "nav.personal.production_operation_dashboard",
        }),
        url: "/pod",
        icon: Frame,
      },
      {
        name: formatMessage({ id: "nav.personal.sales_orders" }),
        url: "/sales/orders",
        icon: Frame,
      },
    ],
  };
  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <a href="#">
                <div className="flex aspect-square size-8 items-center justify-center">
                  <img src="/icon.png" />
                </div>

                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {user?.tenant.title}
                  </span>
                  <span className="truncate text-xs">Enterprise</span>
                </div>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavTop items={data.navTop} />
        <NavMain items={data.navMain} />
        <NavPersonal menu={data.personal} />
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </Sidebar>
  );
}
