import type { DocumentNode } from "graphql";
import { gql } from "graphql-tag";

export const LOGIN: DocumentNode = gql`
  mutation LoginWithEmail(
    $email: String!
    $password: String
    $platform: String!
  ) {
    loginWithEmail(email: $email, password: $password, platform: $platform) {
      id
      firstName
      lastName
      lang
      isActive
      profilePhoto
      token {
        validUntil
        authToken
        platform
      }
      permissions
      tenant {
        name
        title
      }
    }
  }
`;

export const ON_SITE_USER_LOGIN_MUTATION: DocumentNode = gql`
  mutation OnSiteUserLogin(
    $tenantId: ID!
    $onSiteUserId: ID!
    $userId: ID!
    $clientHash: ID!
  ) {
    onSiteUserLogin(
      tenantId: $tenantId
      onSiteUserId: $onSiteUserId
      userId: $userId
      clientHash: $clientHash
    ) {
      firstName
      lastName
      id
      isActive
      tenant {
        name
        title
      }
      permissions
      profilePhoto
      token {
        authToken
        platform
        validUntil
        platformSpecificId
      }
    }
  }
`;

export const ON_SITE_USER_LOGOUT_MUTATION: DocumentNode = gql`
  mutation OnSiteUserLogout(
    $tenantId: ID!
    $onSiteUserId: ID!
    $userId: ID!
    $clientHash: ID!
  ) {
    onSiteUserLogout(
      tenantId: $tenantId
      onSiteUserId: $onSiteUserId
      userId: $userId
      clientHash: $clientHash
    ) {
      status
    }
  }
`;

export const ON_SITE_USER_LOGIN_SUBSCRIPTION: DocumentNode = gql`
  subscription ($clientHash: ID!) {
    onSiteUserLoginSubscription(clientHash: $clientHash) {
      firstName
      lastName
      id
      isActive
      tenant {
        name
        title
      }
      profilePhoto
      token {
        authToken
        platform
        validUntil
        platformSpecificId
      }
    }
  }
`;
export const ON_SITE_USER_LOGOUT_SUBSCRIPTION: DocumentNode = gql`
  subscription ($clientHash: ID!) {
    onSiteUserLogoutSubscription(clientHash: $clientHash) {
      status
    }
  }
`;
export const AUTH_DEVICE_SUBSCRIPTION: DocumentNode = gql`
  subscription ($clientHash: ID!) {
    authDeviceSubscription(clientHash: $clientHash) {
      status
    }
  }
`;

export const LOGOUT: DocumentNode = gql`
  mutation ($platform: String!) {
    appUserLogout(platform: $platform) {
      status
    }
  }
`;

export const CHANGE_MY_PASSWORD: DocumentNode = gql`
  mutation ChangeMyPassword(
    $oldPassword: String
    $newPassword: String
    $newPasswordAgain: String
  ) {
    changeMyPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordAgain: $newPasswordAgain
    ) {
      name
    }
  }
`;

export const RESET_PASSWORD: DocumentNode = gql`
  mutation ResetPassword($email: String) {
    resetPassword(email: $email) {
      newPassword
    }
  }
`;
